import Button from "../button/Button";
import Container from "react-bootstrap/Container";
import React from "react";
import styled from "styled-components";

const Hero1 = () => {
    const buttonDetails = [
        { buttonText: "Get started", link: "/welcome" },
        { buttonText: "Sign in", link: "/login" },
    ];
    return (
        <ContainerStyle fluid>
            <img
                className="img"
                alt="Frame"
                src={require("../../assets/img/PNG/Lines.png")}
            />
            <InnerContainer>
                <TextContainer>
                    <TextBox>
                        <Title>
                            DOCUHELP <SpanText> WRITES </SpanText>
                            BUSINESS DOCUMENTS.
                        </Title>
                    </TextBox>
                    <TextStyle>
                        We are a software company that help businesses build
                        fast business documents that can help the business reach
                        it goals.
                    </TextStyle>
                </TextContainer>
                <InnerWrapper>
                    {buttonDetails.map((detail) => (
                        <a
                            href={detail.link}
                            className={
                                detail.buttonText === "Sign in"
                                    ? "mobileButton"
                                    : ""
                            }
                        >
                            <Button
                                title={detail.buttonText}
                                backgroundColor={
                                    detail.buttonText === "Sign in"
                                        ? "#E8E7FE"
                                        : ""
                                }
                                textColor={
                                    detail.buttonText === "Sign in"
                                        ? "#1A0AD7"
                                        : ""
                                }
                            />
                        </a>
                    ))}
                </InnerWrapper>
            </InnerContainer>
        </ContainerStyle>
    );
};

export default Hero1;

const ContainerStyle = styled(Container)`
    height: 31.4375rem;
    width: 100vw;
    border-top: 1px solid #1a0ad7;
    border-bottom: 1px solid #1a0ad7;
    background-color: #f6f5ff;
    margin-top:5rem;
    position: relative;
    img {
        height: 23.75rem;
        width: 100vw;
        margin-top: 7.5rem;
    }
    @media (min-width: 280px) and (max-width: 912px) {
        height: 100%;
        width: 100%;
        img {
            width: 50rem;
        }
    }
`;

const Title = styled.p`
    color: #0d0c0d;
    text-align: center;
    font-size: 4rem;
    font-weight: 900;
    line-height: 140%; /* 3.5rem */
    letter-spacing: -0.0125rem;
    @media (min-width: 280px) and (max-width: 912px) {
        font-size: 2rem;
    }
`;
const SpanText = styled.span`
    color: #4435f6;
    font-size: 4rem;
    font-weight: 900;
    line-height: 140%;
    letter-spacing: -0.0125rem;
    @media (min-width: 280px) and (max-width: 912px) {
        font-size: 2rem;
    }
`;
const TextBox = styled.div`
    width: 53.1875rem;
    @media (min-width: 280px) and (max-width: 912px) {
        width: 85%;
    }
`;

const TextStyle = styled.p`
    color: #0d0c0d;
    text-align: center;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 160%; /* 2rem */
    letter-spacing: 0.00625rem;
    width: 45rem;
    @media (min-width: 280px) and (max-width: 912px) {
        font-size: 1.125rem;
        width: 80%;
    }
`;
const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
const InnerWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.25rem;
    button {
        border-radius: 0.5rem;
        padding: 1.25rem 2.4375rem;
        height: 4rem;
        @media (min-width: 280px) and (max-width: 912px) {
            height: 3rem;
            padding: 1rem 1.75rem;
            font-size: 1rem;
        }
    }
    a {
        text-decoration: none;
    }
    @media (min-width: 280px) and (max-width: 912px) {
        .mobileButton {
            display: none;
        }
    }
`;
const InnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`;
