import Button from "../button/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { LinkStyle } from "../footers/LandingFooter";
import React from "react";
import Row from "react-bootstrap/Row";
import styled, { css } from "styled-components";

/**
 * Component displaying a hero section with an image and a textbox.
 * @component
 */

const Hero2 = () => {
    return (
        <ContainerStyle fluid>
            <Row>
                <Col lg={4}>
                    <TextBox>
                        <TitleText className="fs-1 fw-bold">
                            Write with docuhelp
                        </TitleText>
                        <StyledP>
                            Generate professional graded documents, reports,
                            proposal, sales pitch in a matter of minutes and
                            empower your team to work together in real time,
                            ensuring accuracy and efficiency. No more email
                            chains or confusion—just smooth, simultaneous
                            collaboration.
                        </StyledP>
                        <LinkStyle href="/welcome" rel="noopener noreferrer">
                            <Button
                                onClick={""}
                                title="Get Started"
                                backgroundColor="#1A0AD7"
                                textColor="#fff"
                                padding="2rem 2.5rem"
                                borderRadius="0.5rem"
                            />
                        </LinkStyle>
                    </TextBox>
                </Col>

                <ColStyle lg={8}>
                    <Image
                        className="img-fluid"
                        src={require("../../assets/img/manwithgadget.png")}
                        alt="man with laptop"
                    ></Image>
                </ColStyle>
            </Row>
        </ContainerStyle>
    );
};
export default Hero2;

export const ContainerStyle = styled(Container)`
    @media (max-width: 800px) {
        button {
            display: none;
        }
    }
    @media (min-width: 992px) and (max-width: 1400px) {
        height: 100%;
    }
`;

const ColStyle = styled(Col)`
    display: flex;
    justify-content: flex-end;
`;
export const TitleText = styled.h1`
    color: var(--Text-color, #0d0c0d);
    font-size: 4rem !important;
    font-weight: 800 !important;
    @media (min-width: 280px) and (max-width: 700px) {
        font-size: 2rem !important;
    }
    @media only screen and (min-width: 1024px) and (max-width: 1024px) {
        font-size: 2rem !important;
    }
`;

export const StyledP = styled.p`
    color: #4b4b4e;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
    letter-spacing: -0.00625rem;

    @media (min-width: 280px) and (max-width: 700px) {
        font-size: 1rem !important;
    }
    @media only screen and (min-width: 1024px) and (max-width: 1024px) {
        font-size: 1rem;
    }
    @media only screen and (min-width: 2560px) and (max-width: 2560px) {
        font-size: 1.5rem;
    }
`;
export const Image = styled.img`
    margin-top: 8rem;
    @media (min-width: 280px) and (max-width: 912px) {
        margin-top: 0;
        width: 100%;
    }
    @media only screen and (min-width: 1024px) {
        width: 100%;
    }
    @media (min-width: 992px) and (max-width: 1400px) {
        width: 100%;
    }
    @media only screen and (min-width: 2560px) and (max-width: 2560px) {
        width: 50%;
        margin-left: auto;
    }
`;
export const TextBox = styled.section`
    margin: 10rem 3rem;
    width: 34.0625rem;
    display: flex;
    flex-direction: column;
    @media (min-width: 280px) and (max-width: 700px) {
        margin: 6.3rem 0;
        width: 100%;
        margin-top: 50px;
    }
    @media (min-width: 992px) and (max-width: 1400px) {
        margin: 2rem;
    }
    @media only screen and (min-width: 2560px) {
        width: 100%;
        margin-left:8rem;
    }
`;
