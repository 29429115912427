import Button from "../../button/Button";
import React from "react";
import styled from "styled-components";

const Hero3Card = ({ bgImg, titleText, text }) => {
    return (
        <CardWrapper>
            <Icon $bgImage={bgImg}></Icon>
            <TitleText> {titleText}</TitleText>
            <SubText>{text}</SubText>
            <a href="/welcome">
                <Button title="Start Writing" />
            </a>
        </CardWrapper>
    );
};

export default Hero3Card;

const CardWrapper = styled.div`
    width: 100%;
    height: 100%;
    padding: 9rem 5rem 5rem 5rem;
    button {
        margin-top: 7rem;
        display: flex;
        padding: 2rem 3.0625rem;
        justify-content: center;
        align-items: center;
        border-radius: 0.5rem;
        border: 1px solid #1a0ad7;
        background: #e8e7fe;
        color: #1a0ad7;
        font-size: 1.125rem;
        font-weight: 700;
        line-height: 140%;
        letter-spacing: -0.00563rem;
    }
    a{
        text-decoration:none;
    }
    @media (min-width: 280px) and (max-width: 912px) {
        padding: 2.5rem 4.4375rem 2.5rem 1.1875rem;
        height: auto;
        button {
            padding: 1.5rem 2.5rem;
            font-size: 1rem;
            margin-top: 1.5rem;
        }
    }
`;

const Icon = styled.div`
    background: url("${({ $bgImage }) => $bgImage}") no-repeat center;
    background-size: 100% 100%;
    width: 7.5rem;
    height: 7.5rem;
    border-radius: 50%;
    @media (min-width: 280px) and (max-width: 912px) {
        width: 5rem;
        height: 5rem;
    }
`;
const TitleText = styled.p`
    margin-top: 3rem;
    color: #0d0c0d;
    font-size: 2rem;
    font-weight: 700;
    line-height: 120%;
    @media (min-width: 280px) and (max-width: 912px) {
        font-size: 1.75rem;
        margin-top: 1.5rem;
    }
`;
const SubText = styled.p`
    color: #4b4b4e;
    font-size: 1.375rem;
    font-weight: 600;
    line-height: 160%;
    margin-top: 1.25rem;
    @media (min-width: 280px) and (max-width: 912px) {
        font-size: 1rem;
        margin-top: 1rem;
    }
`;
