import React from "react";
import styled from "styled-components";

const Button = (props) => {
    const {
        id,
        type,
        disabled,
        onClick,
        backgroundColor,
        textColor,
        padding,
        title,
        borderRadius,
        height,
        width,
        noborder,
    } = props;
    const customStyles = {
        background: backgroundColor,
        color: textColor,
        padding: padding,
        borderRadius: borderRadius,
        height: height,
        width: width,

    };

    return (
        <StyledButton
            id={id}
            type={type}
            disabled={disabled}
            onClick={onClick}
            style={customStyles}
            $noborder={noborder}
        >
            {title}
        </StyledButton>
    );
};

export default Button;

const StyledButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.25rem;
    background: var(--primary, #1a0ad7);
    white-space: nowrap;
    padding: 0.5rem 0.875rem;
    height: 3rem;
    color: #fff;
    font-size: 1.125rem;
    outline: none;
    border: ${({ $noborder }) => ($noborder ? "none" : "1px solid #1a0ad7")};
    cursor: pointer;
    font-weight: 600;
    line-height: 160%;
    letter-spacing: 0.15px;
`;
