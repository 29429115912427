import Accordion from "react-bootstrap/Accordion";
import Container from "react-bootstrap/Container";
import styled from "styled-components";

/**
 * Hero5 Component
 *
 * This component renders an Accordion with custom-styled headers and bodies.
 * The Accordion has three items, each consisting of a header and a body.
 *
 * @returns {JSX.Element} The JSX representation of the Hero5 component.
 */

function Hero5() {
    return (
        <ContainerStyle fluid>
            <StyledAccordion defaultActiveKey="">
                <Title>Why Docuhelp?</Title>
                <StyledAccordionItem eventKey="0">
                    <StyledAccordionHeader>
                        Industry Focused
                    </StyledAccordionHeader>
                    <StyledAccordionBody>
                        Backend prompts can be focused mainly on your industry
                        so results are more precise.
                    </StyledAccordionBody>
                </StyledAccordionItem>
                <StyledAccordionItem eventKey="1">
                    <StyledAccordionHeader>
                        Access to Backends
                    </StyledAccordionHeader>
                    <StyledAccordionBody>
                        Access to a backend system to see what prompts staffs
                        are sending and results generated.
                    </StyledAccordionBody>
                </StyledAccordionItem>
                <StyledAccordionItem eventKey="2">
                    <StyledAccordionHeader>
                        Trained on your data
                    </StyledAccordionHeader>
                    <StyledAccordionBody>
                        Models can be built that’s trained solely on your
                        company data and documents.Analytics and fine tuning of
                        models based on specific use cases.
                    </StyledAccordionBody>
                </StyledAccordionItem>
            </StyledAccordion>
        </ContainerStyle>
    );
}

export default Hero5;

export const Title = styled.h1`
    color: #1a0ad7;
    font-size: 4rem;
    font-style: normal;
    font-weight: 900;
    line-height: 140%;
    letter-spacing: -0.02rem;
    text-align: center;
    margin-top: 7.5rem;
    @media (min-width: 280px) and (max-width: 800px) {
        font-size: 2rem;
        margin-top: 0;
    }
`;

export const StyledAccordionHeader = styled(Accordion.Header)`
    padding: 0rem 1rem 0 1rem;
    border-radius: 1.75rem;
    height: fit-content;
    display: flex;
    width: 44.125rem;
    height: 6.25rem;
    border: none;
    button {
        padding: 2rem;
        border-radius: 0.75rem;
        background: #f6f5ff;
        color: var(--text-color, #0d0c0d);
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 140%;
        letter-spacing: -0.0075rem;
        border: none;
    }
    .accordion-button:not(.collapsed) {
        color: var(--text-color, #0d0c0d);
        background-color: #f6f5ff;
        box-shadow: none;
        padding: 2.5rem 2rem 0rem 2rem;
        z-index: 1;
        border: none;
    }
    .accordion-button:focus {
        padding: 2rem;
        box-shadow: 0 0 0 0;
        height: 5rem;
        border-radius: 0.75rem;
        transform: translateY(0.7rem);
    }

    @media (max-width: 800px) {
        width: 100%;
    }
`;
export const StyledAccordionItem = styled(Accordion.Item)`
    background: #f6f5ff;
    margin: 1rem auto 2rem 0;

    @media (max-width: 800px) {
        margin-bottom: 1rem;
    }
`;

export const ContainerStyle = styled(Container)`
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 800px) {
        margin-top: 4rem;
        padding: 1rem;
    }
`;
export const StyledAccordion = styled(Accordion)`
    width: 44.125rem;
    margin-bottom: 7rem;
    .accordion-item {
        border: 1px solid var(--primary, #1a0ad7);
        background: #f6f5ff;
        border-radius: 0.75rem;
    }

    @media (max-width: 800px) {
        width: 100%;
    }
`;
export const StyledAccordionBody = styled(Accordion.Body)`
    border: none;
    padding: 3rem 3rem 0 3rem;
    max-width: 100%;
    width: 44.125rem;
    height: 6.25rem;
    margin: -1.4rem 0 3rem 0;
    color: var(--text-color, #0d0c0d);
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.0075rem;

    @media (max-width: 800px) {
        width: 100%;
        height: 100%;
        font-size: 0.8rem;
    }
`;
