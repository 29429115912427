import CookieBanner from "../../components/privacyPolicy/CookieBanner.js";
import {Cta } from "../../components/hero/cta-section/Cta.jsx";
import Header from "../../components/navigation/Header.jsx";
import Hero1 from "../../components/hero/Hero1.jsx";
import Hero2 from "../../components/hero/Hero2.js";
import Hero3 from "../../components/hero/hero3/Hero3.jsx";
import Hero4 from "../../components/hero/Hero4.js";
import Hero5 from "../../components/hero/Hero5.js";
import LandingFooter from "../../components/footers/LandingFooter.js";
import {Pricing} from "../../components/hero/pricing/pricing.jsx";
import "react-whatsapp-widget/dist/index.css";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { WhatsAppWidget } from "react-whatsapp-widget";

function Landing() {
    const [isCookieAccepted, setIsCookieAccepted] = useState(false);

    useEffect(() => {
        const userCookieConsent = localStorage.getItem("cookieConsent");
        setIsCookieAccepted(userCookieConsent === "accepted");
    }, []);

    const handleCookieAccept = () => {
        localStorage.setItem("cookieConsent", "accepted");
        setIsCookieAccepted(true);
    };

    const handleCookieReject = () => {
        localStorage.setItem("cookieConsent", "rejected");
        setIsCookieAccepted(true);
    };
    return (
        <LandingContainer>
            <Overlay isCookieAccepted={isCookieAccepted}></Overlay>
            {!isCookieAccepted && (
                <CookieBanner
                    onAccept={handleCookieAccept}
                    onReject={handleCookieReject}
                />
            )}
            <Header></Header>
            <Hero1></Hero1>
            <Cta></Cta>
            <Hero2></Hero2>
            <Hero3 />
            <Hero4></Hero4>
            <Hero5></Hero5> 

            // <Pricing></Pricing>

            <WhatsAppWidget
                CompanyName="Docuhelp"
                phoneNumber="2349034104100"
            />
            <LandingFooter></LandingFooter>
        </LandingContainer>
    );
}

const LandingContainer = styled.div`
    position: relative;
    overflow: hidden;
`;

const Overlay = styled.div`
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    display: ${(props) => (props.isCookieAccepted ? "none" : "block")};
`;

export default Landing;
