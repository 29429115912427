import Button from "../button/Button";
import { BsTelephone } from "react-icons/bs";
import { BsWhatsapp } from "react-icons/bs";
import { CiMail } from "react-icons/ci";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { GrInstagram, GrLinkedin, GrTwitter } from "react-icons/gr";
import logo from "../../assets/img/Group.svg";
import "react-toastify/dist/ReactToastify.css";
import Row from "react-bootstrap/Row";
import { SlLocationPin } from "react-icons/sl";
import styled from "styled-components";
import { ToastContainer, toast } from "react-toastify";
import { useState } from "react";
import { appName, apiKey, portalId, formId } from "../../configs/index.js";

/**
 * LandingFooter Component
 *
 * This component represents the footer section of the landing page.
 * It displays various information like Stay In Touch section with email subscription,
 * Company information, Contact Us details, logo, and social media icons.
 *
 * @returns {JSX.Element} JSX Element representing the LandingFooter component.
 */

function LandingFooter() {
    const [email, setEmail] = useState("");
    const [validEmail, setValidEmail] = useState(true);
    const [submitted, setSubmitted] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    /**
     * Event handler for the email input change.
     *
     * @param {Event} event The event object.
     */
    const handleChange = (event) => {
        event.preventDefault();
        const enteredEmail = event.target.value;

        // Basic email validation using a regex
        const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(enteredEmail);

        // Update the state and display a validation message
        setEmail(enteredEmail);
        setValidEmail(isValidEmail);
    };

    /**
     * Event handler for the subscribe button click.
     */
    const handleSubscribe = async () => {
        if (!validEmail) {
            setErrorMessage(
                "Invalid email! Please enter a valid email address."
            );
            setSubmitted(false);
            return;
        }
      
        const data = {
            fields: [
                {
                    name: "email",
                    value: email,
                },
            ],
        };

        try {
            const response = await fetch(
                `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${apiKey}`,
                    },
                    body: JSON.stringify(data),
                }
            );

            if (response.ok) {
                console.log("Email submitted successfully!");
                setSubmitted(true);
                setErrorMessage("");
                toast.success("Thank you for subscribing to Docuhelp!", {
                    position: "bottom-left",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                setEmail("");
                setValidEmail(true);
            } else {
                console.error(
                    "Error submitting email:",
                    response.status,
                    response.statusText
                );
                setErrorMessage(
                    "Something went wrong. Please try again later."
                );
                setSubmitted(false);
            }
        } catch (error) {
            console.error("Error submitting email:", error);
            setErrorMessage("Something went wrong. Please try again later.");
            setSubmitted(false);
        }
    };

    return (
        <>
            <ContainerStyle fluid>
                <RowStyle md={4}>
                    <StyledCol xs={6}>
                        <TextH4>
                            Stay In Touch
                            <br />
                            With Us
                        </TextH4>
                        <StyledText>
                            The latest Docuhelp news, articles, and resources,
                            sent straight to your inbox every month.
                        </StyledText>
                        <InputWrapper>
                            <StyledInput
                                type="email"
                                id=""
                                name="email address"
                                value={email}
                                placeholder="Email Address"
                                onChange={handleChange}
                            />
                            <Button
                                onClick={handleSubscribe}
                                title="Subscribe"
                            />
                        </InputWrapper>
                        {!validEmail && (
                            <p style={{ color: "red" }}>
                                Please enter a valid email address.
                            </p>
                        )}
                        {errorMessage && submitted && (
                            <p style={{ color: "red", marginTop: "1rem" }}>
                                {errorMessage}
                            </p>
                        )}
                        <ToastContainer />
                    </StyledCol>
                    <StyledCol xs={3}>
                        <LinkStyle href="http://3d7tech.com">
                            <TextH6>Company</TextH6>
                        </LinkStyle>
                        <LinkStyle
                            href="http://3d7tech.com"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <LinkP>3d7 Technologies</LinkP>
                        </LinkStyle>
                        {/* <LinkStyle
                            href="https://www.3d7tech.com/#products"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <LinkP>Products</LinkP>
                        </LinkStyle>
                        <LinkStyle href="#">
                            <LinkP>Legal</LinkP>
                        </LinkStyle> */}
                        <LinkStyle
                            href="https://docs.google.com/document/d/15V2EEZDyBhhwYxIaf7-sxw77LuQvs9FcVY-86Zh3NDM/edit?usp=sharing"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <LinkP>View our Roadmap</LinkP>
                        </LinkStyle>
                    </StyledCol>

                    <StyledCol xs={3}>
                        <TextH6>Contact Us</TextH6>
                        <IconsWrap>
                            <CiMail style={{ fontSize: "1.2rem" }} />
                            <LinkStyle href="mailto:hello@docuhelp.ai">
                                <LinkP>hello@docuhelp.ai</LinkP>
                            </LinkStyle>
                        </IconsWrap>
                        <IconsWrap>
                            <SlLocationPin style={{ fontSize: "1.2rem" }} />
                            <LinkP>Lagos, Nigeria</LinkP>
                        </IconsWrap>
                        <IconsWrap>
                            <BsTelephone style={{ fontSize: "1.2rem" }} />
                            <LinkP>+234 903 410 4100</LinkP>
                        </IconsWrap>
                    </StyledCol>
                    <StyledCol xs={3}>
                        <LinkStyle href="#">
                            <Logo
                                alt="logo"
                                src={require("../../assets/img/Logo/PNG/logoMain.png")}
                            />
                        </LinkStyle>

                        <StyledP>Write business documents.</StyledP>
                        <StyledTextTwo>
                            DocuHelp may produce inaccurate information about
                            people, places, or facts and may also display
                            inaccurate or offensive information that doesn’t
                            represent 3d7 Technologies views.
                        </StyledTextTwo>
                    </StyledCol>
                </RowStyle>
                <StyledRow>
                    <StyledSection>
                        <FooterWrapper>
                            <a href="#">
                                <ImageWrapper>
                                    <img src={logo} alt="logo" />
                                </ImageWrapper>
                            </a>
                            <StyledSpan>
                                © 2023 docuhelp.ai | Security |{" "}
                                <LinkStyle
                                    href="/privacy-policy"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Your Privacy
                                </LinkStyle>{" "}
                                |{" "}
                                <LinkStyle
                                    href="#"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Terms
                                </LinkStyle>
                            </StyledSpan>
                        </FooterWrapper>

                        <StyledColumn>
                            <IconsWrap>
                                <LinkStyle
                                    href="https://www.linkedin.com/company/3d7-technologies-ltd"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <GrLinkedin />
                                </LinkStyle>
                                <LinkStyle
                                    href="https://www.instagram.com/3d7tech/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <GrInstagram />
                                </LinkStyle>
                                <LinkStyle
                                    href="https://twitter.com/3d7techdotcom"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <GrTwitter />
                                </LinkStyle>
                                <LinkStyle
                                    href="https://wa.me/message/PMH3PQH4SDYVN1"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <BsWhatsapp />
                                </LinkStyle>
                            </IconsWrap>
                        </StyledColumn>
                    </StyledSection>
                </StyledRow>
            </ContainerStyle>
        </>
    );
}

export default LandingFooter;

const StyledCol = styled(Col)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
    margin-top: 2rem;
    width: fit-content;

    @media (min-width: 280px) and (max-width: 320px) {
        button {
            font-size: 0.56rem;
            height: 2rem;
            margin-right: 1rem;
        }
    }
`;

const StyledText = styled.p`
    font-size: 1rem;
    font-weight: 400;
    line-height: 160%;
    width: 23.625rem;
    margin-top: -1rem;
    @media (min-width: 300px) and (max-width: 800px) {
        max-width: 100%;
    }
`;

const InputWrapper = styled.div`
    border: 1px solid #1a0ad7;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 21.8125rem;
    height: 4rem;
    border-radius: 0.5rem;
    background: #edebfe;
    margin-top: -1rem;
    @media (min-width: 280px) and (max-width: 800px) {
        width: 87%;
        justify-content: space-around;
    }
`;

const StyledInput = styled.input`
    border: none;
    font-size: 1rem;
    font-weight: 400;
    line-height: 160%;
    margin: 0.1rem;
    background: #edebfe;
    outline: none;
    &::placeholder {
        color: #776df8 !important;
    }

    @media (min-width: 280px) and (max-width: 800px) {
        margin: 0 -2rem 0 0rem;
        font-size: 0.8rem;
    }
`;

const Logo = styled.img`
    width: 12.4375rem;
    height: 3.8923rem;

    @media (min-width: 300px) and (max-width: 800px) {
    }
`;

const IconsWrap = styled.div`
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    p {
        margin: auto 0;
    }
`;

const RowStyle = styled(Row)`
    display: flex;
    justify-content: space-between;
    padding: 3rem;

    @media (min-width: 280px) and (max-width: 800px) {
        flex-direction: column;
        padding: 0;
    }
`;

const ImageWrapper = styled.div`
    width: 2rem;
    height: 2.18519rem;
    @media (min-width: 280px) and (max-width: 800px) {
        width: 100%;
    }
`;

export const LinkStyle = styled.a`
    text-decoration: none;
    color: #fcfcfc;
`;

export const LinkP = styled.p`
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    margin: -0.1rem;
`;
const TextH4 = styled.h4`
    leading-trim: both;
    text-edge: cap;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: 0.8px;
    width: 291px;
`;
const TextH6 = styled.h6`
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
`;

const StyledP = styled.p`
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 1.4rem */
`;
const StyledTextTwo = styled.p`
    margin-top: -2rem;
    font-size: 1rem;
    font-weight: 300;
    line-height: 160%;
    max-width: 23.4375rem;
    @media (min-width: 280px) and (max-width: 912px) {
        max-width: 100%;
    }
`;
const ContainerStyle = styled(Container)`
    padding-top: 3rem;
    background: #0d0c0d;
    color: #fcfcfc;
`;

const StyledSpan = styled.span`
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 0.8125rem;

    @media (min-width: 280px) and (max-width: 912px) {
        font-size: 2.5vw;
        align-items: flex-start;
        width: 100%;
    }
`;
const StyledRow = styled(Row)`
    justify-content: center;
    border-top: 1px solid rgba(153, 153, 153, 0.5);
    padding: 2rem 3rem 2rem 3rem;

    @media (min-width: 280px) and (max-width: 912px) {
        flex-direction: column;
        padding: 2rem 0.5rem 5.9rem 0.5rem;
        align-items: center;
    }
`;
const StyledSection = styled.section`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 1.25rem;
    @media (min-width: 280px) and (max-width: 912px) {
        width: 100%;
        position: relative;
    }
`;

const StyledColumn = styled(Col)`
    display: flex;
    justify-content: flex-end;
    font-size: 1.5rem;
    @media (min-width: 280px) and (max-width: 912px) {
        width: 100%;
        position: absolute;
        top: 3rem;
        justify-content: center;
    }
`;
const FooterWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`;
