import React from "react";
import "./style2.css";
import { LinkStyle } from "../../footers/LandingFooter";


export const Pricing = () => {
  return (
    <div className="landing-page">
      <div className="div">
       
        <div className="frame-11">
          <div className="frame-12">
            <div className="text-wrapper-10">Pricing</div>
            <p className="text-wrapper-11">Find a flexible plan that scales with your business.</p>
          </div>
          <div className="frame-13">
            <div className="frame-14">
              <div className="text-wrapper-12">FREE PLAN</div>
            </div>
            <div className="frame-15">
              <div className="frame-16">
                <p className="text-wrapper-13">For teams creating their first business documents.</p>
                <div className="text-wrapper-14">$10</div>
              </div>
              <LinkStyle href="/welcome" rel="noopener noreferrer">
              <div className="frame-17">
                <div className="text-wrapper-15">Start for free <span className="smalltext"> (No credit card required)</span></div>
              </div>
              </LinkStyle>
              <div className="frame-18">
                <div className="text-wrapper-16">Added features</div>
                <div className="frame-19">
                  <div className="frame-4">
                    <img className="frame-20" alt="Frame" src={require("../../../assets/img/dark-frame.png")} />
                    <div className="text-wrapper-17">Streamline document workflow</div>
                  </div>
                  <div className="frame-4">
                    <img className="frame-20" alt="Frame" src={require("../../../assets/img/dark-frame.png")} />
                    <div className="text-wrapper-17">Dedicated customer support</div>
                  </div>
                  <div className="frame-4">
                    <img className="frame-20" alt="Frame" src={require("../../../assets/img/dark-frame.png")} />
                    <p className="text-wrapper-17">One month free trial period</p>
                  </div>
                  <div className="frame-4">
                    <img className="frame-20" alt="Frame" src={require("../../../assets/img/dark-frame.png")} />
                    <div className="text-wrapper-17">License for all Teams</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>



    // <div className="frame">
    //   <div className="div">
    //     <div className="text-wrapper">Pricing</div>
    //     <p className="p">Find a flexible plan that scales with your business.</p>
    //   </div>
    //   <div className="div-2">
    //     <div className="div-wrapper">
    //       <div className="text-wrapper-2">FREE PLAN</div>
    //     </div>
    //     <div className="div-3">
    //       <div className="div-4">
    //         <p className="text-wrapper-3">For teams creating their first business documents.</p>
    //         <div className="text-wrapper-4">$10</div>
    //       </div>
    //       <div className="div-wrapper-2">
    //         <div className="text-wrapper-5">Start for free</div>
    //       </div>
    //       <div className="div-5">
    //         <div className="text-wrapper-6">Added features</div>
    //         <div className="div-6">
    //           <div className="div-7">
    //             <img className="img" alt="Frame" src="frame.svg" />
    //             <div className="text-wrapper-7">Streamline document workflow</div>
    //           </div>
    //           <div className="div-7">
    //             <img className="img" alt="Frame" src="image.svg" />
    //             <div className="text-wrapper-7">Dedicated customer support</div>
    //           </div>
    //           <div className="div-7">
    //             <img className="img" alt="Frame" src="frame-2.svg" />
    //             <p className="text-wrapper-7">One month free trial period</p>
    //           </div>
    //           <div className="div-7">
    //             <img className="img" alt="Frame" src="frame-3.svg" />
    //             <div className="text-wrapper-7">License for all Teams</div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};
