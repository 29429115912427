import Button from "../button/Button";
import { GiHamburgerMenu } from "react-icons/gi";
import { GrClose } from "react-icons/gr";
import Logo from "../../assets/img/Logo/PNG/Logo.png";
import React, { useState } from "react";
import styled from "styled-components";

const Header = () => {
    const [buttonDisplay, setButtonDisplay] = useState("none");

    const buttonDetails = [
        { buttonText: "Get started", link: "/welcome" },
        { buttonText: "Sign in", link: "/login" },
    ];

    const toggleDisplay = () => {
        buttonDisplay === "none"
            ? setButtonDisplay("flex")
            : setButtonDisplay("none");
    };
    return (
        <HeaderWrapper>
            <Navbar>
                <NavbarLeft>
                    <LogoLink href="#">
                        <StyledLogo></StyledLogo>
                        <span>docuhelp.ai</span>
                    </LogoLink>
                    <a
                        className="blog"
                        href="https://blog.docuhelp.ai/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Blog
                    </a>
                     <a
                        className="blog"
                        href="https://docuhelp.ai/pricing"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Pricing
                    </a>
                </NavbarLeft>
                <NavbarRight $display={buttonDisplay}>
                    <a
                        className="blog mobile-blog"
                        href="https://blog.docuhelp.ai/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Blog
                    </a>
                    <a
                        className="blog mobile-blog"
                        href="https://docuhelp.ai/pricing"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Pricing
                    </a>
                    {buttonDetails.map((detail) => (
                        <a
                            href={detail.link}
                            className={
                                detail.buttonText === "Sign in"
                                    ? "mobileButton"
                                    : ""
                            }
                        >
                            <Button
                                noborder={detail.buttonText === "Sign in"}
                                title={detail.buttonText}
                                backgroundColor={
                                    detail.buttonText === "Sign in"
                                        ? "#E8E7FE"
                                        : ""
                                }
                                textColor={
                                    detail.buttonText === "Sign in"
                                        ? "#1A0AD7"
                                        : ""
                                }
                            />
                        </a>
                    ))}
                </NavbarRight>
                {buttonDisplay === "flex" ? (
                    <GrCloseDiv onClick={toggleDisplay}>
                        <GrClose />
                    </GrCloseDiv>
                ) : (
                    <GiHamburgerMenu
                        style={{ width: "2rem", height: "2rem" }}
                        onClick={toggleDisplay}
                    />
                )}
            </Navbar>
        </HeaderWrapper>
    );
};

export default Header;

const HeaderWrapper = styled.header`
    height: 5rem;
    padding: 0rem 5rem;
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 9999;
    background: white;
    width: 100vw;
    @media (min-width: 280px) and (max-width: 912px) {
        padding: 0 1.25rem;
    }
`;
const Navbar = styled.nav`
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    svg {
        display: none;
    }
    @media (min-width: 280px) and (max-width: 912px) {
        svg {
            display: flex;
        }
    }
`;
const StyledLogo = styled.div`
    background: url("${Logo}") no-repeat center;
    background-size: 100% 100%;
    width: 2rem;
    height: 2.18519rem;
`;
const LogoLink = styled.a`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1rem;
    font-weight: 700;
    color: #4435f6;
`;
const NavbarLeft = styled.div`
    display: flex;
    align-items: center;
    gap: 2rem;
    a {
        text-decoration: none;
    }
    .blog {
        color: #0a0a0a;
        @media (min-width: 280px) and (max-width: 912px) {
            display: none;
        }
    }
`;
const NavbarRight = styled.div`
    display: flex;
    align-items: center;
    gap: 1.8rem;

    .mobile-blog {
        display: none;
    }
    @media (min-width: 280px) and (max-width: 912px) {
        display: ${({ $display }) => $display};
        position: absolute;
        width: 100vw;
        flex-direction: column;
        top: 100%;
        left: 0;
        z-index: 999;
        background: white;
        height: calc(100vh - 4rem);
        justify-content: flex-end;
        padding: 0 1.25rem 18rem 1.25rem;
        .mobile-blog {
            display: flex;
            color: #0a0a0a;
        }
        .mobileButton {
            display: none;
        }
    }
    a {
        text-decoration: none;
        @media (min-width: 280px) and (max-width: 912px) {
            width: 100%;
            display: flex;
            justify-content: center;
        }
    }
    button {
        border-radius: 0.5rem;
        padding: 1rem 1.5rem;
        @media (min-width: 280px) and (max-width: 912px) {
            width: 100%;
        }
    }
`;
const GrCloseDiv = styled.div`
    display: none;
    @media (min-width: 280px) and (max-width: 912px) {
        width: 2.3rem;
        height: 2.3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 6.25rem;
        background: #f6f6f6;
        font-size: 1.3rem;
    }
`;
