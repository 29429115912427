import "../../assets/css/landingHero4.css";
import Button from "../button/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import styled from "styled-components";
import { LinkStyle } from "../footers/LandingFooter";

function Hero4() {
    return (
        <Wrapper fluid className="bg-light">
            <Row>
                <Col
                    lg={6}
                    className="d-flex justify-content-center align-items-center"
                >
                    <TextBox className="  w-75 d-inline-block">
                        <StyledP>
                            Use DocuHelp to write documents for your business,
                            work report, and proposals for your next project!
                        </StyledP>
                          <LinkStyle
                                href="/welcome"
                                
                                rel="noopener noreferrer"
                            >
                        <Button
                            onClick={""}
                            title="Get Started"
                            backgroundColor="#1A0AD7"
                            textColor="#fff"
                            borderRadius="0.5rem"
                        /></LinkStyle>
                    </TextBox>
                </Col>
                <ColStyleImg lg={6}>
                    <Image
                        className="img-fluid"
                        src={require("../../assets/img/Illustration/PNG/womanwithlaptop.png")}
                        alt="woman with laptop"
                    ></Image>
                </ColStyleImg>
            </Row>
        </Wrapper>
    );
}

export default Hero4;

export const Wrapper = styled(Container)`
    @media (max-width: 800px) {
        button {
            padding: 1rem 0.5rem;
            font-size: 1rem;
        }
    }
`;

export const ColStyleImg = styled(Col)`
        @media (min-width: 280px) and (max-width: 768px) {
            display:flex;
        }
`;
export const Image = styled.img`
    height: 80vh;
    margin-top: 5rem;
    @media (min-width: 280px) and (max-width: 768px) {
        height: 80%;
        margin: auto;
    }
    @media (min-width: 769px) and (max-width: 1024px) {
        width: 50%;
        height: 80%;
        margin-left: 10rem;
    }
    @media only screen and (min-width: 2560px) {
        width: 50%;
        height: 90%;
    }
`;

export const TextBox = styled.div`
    background: #fff;
    border-top: 4px solid #1a0ad7 !important;
    padding: 1rem;
    gap: 1.5rem;
    @media (min-width: 280px) and (max-width: 768px) {
        margin-top: 3rem;
        width: 100%;
    }
    @media (min-width: 768px) and (max-width: 1024px) {
        margin-top: 3rem;
    }
`;
export const StyledP = styled.p`
    color: var(--Primary, #1a0ad7);
    font-size: 2.2rem;
    font-style: normal;
    font-weight: 900;
    line-height: 140%; /* 3.5rem */
    letter-spacing: -0.0125rem;
    @media (min-width: 280px) and (max-width: 800px) {
        font-size: 1.2rem;
    }
    @media only screen and (min-width: 2560px) and (max-width: 2560px) {
        font-size: 2.5rem;
    }
`;
