import Button from "../button/Button";
import React, { useState, useEffect } from "react";
import styled from "styled-components";

const CookieBanner = ({ onAccept, onReject }) => {
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(true);
        }, 2000);

        return () => {
            clearTimeout(timer);
        };
    }, []);

    const handleAcceptClick = () => {
        setIsVisible(false);
        onAccept();
    };

    const handleRejectClick = () => {
        setIsVisible(false);
        onReject();
    };
    return (
        isVisible && (
            <CookieConsentStyle>
                <TextStyle>
                    This site uses cookies to ensure you get the best experience
                    on our website. To learn more visit our{" "}
                    <LinkStyle
                        href="/privacy-policy"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Privacy Policy
                    </LinkStyle>
                    
                </TextStyle>
                <ButtonDiv>
                    <Button
                        onClick={handleRejectClick}
                        title="Reject All Cookies"
                        backgroundColor="#fff"
                        textColor="var(--primary, #1A0AD7)"
                        padding="1rem 1.5rem"
                        borderRadius="0.5rem"
                    />
                    <Button
                        onClick={handleAcceptClick}
                        title="Accept All Cookies"
                        backgroundColor="var(--primary, #1A0AD7)"
                        textColor="#fff"
                        padding="1rem 1.5rem"
                        borderRadius="0.5rem"
                    />
                </ButtonDiv>
            </CookieConsentStyle>
        )
    );
};

export default CookieBanner;

export const CookieConsentStyle = styled.div`
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    z-index: 9999;
    display: flex;
    justify-content: space-between;
    height: 2.5rem;
    padding: 1.9375rem 2.5rem 3.5rem 3rem;

    @media (min-width: 280px) and (max-width: 800px) {
        padding: 0.5rem;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        height: auto;
    }
`;
export const ButtonDiv = styled.div`
    display: flex;
    gap: 1rem;
    @media (min-width: 280px) and (max-width: 800px) {
        button {
            width: 7rem;
            font-size: 0.6rem;
            padding: 0;
        }
    }
`;

export const TextStyle = styled.p`
    color: var(--text-color, #0d0c0d);
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    width: 38rem;
    letter-spacing: -0.00563rem;
    @media (min-width: 280px) and (max-width: 800px) {
        font-size: 1rem;
        width: 100%;
    }
`;
export const LinkStyle = styled.a`
    color: var(--primary, #1a0ad7);
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 160%;
    letter-spacing: -0.00563rem;
    text-decoration: none;
    @media (min-width: 280px) and (max-width: 800px) {
        font-size: 1rem;
    }
`;
