import Arrow from "../../../assets/img/Icons/SVG/arrow-1.svg";
import { cardDetails } from "./hero3Data";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Hero3Card from "./Hero3Card";
import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import styled, { keyframes, css } from "styled-components";

const Hero3 = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % 4);
        }, animationDuration + pauseDuration);

        return () => clearInterval(interval);
    }, []);

    return (
        <ContainerStyle fluid>
            <Row>
                <ColOne lg={6} sm={12}>
                    <TextBox>
                        <PStyle>Help your team get better</PStyle>
                        <SubHeadStyleDiv>
                            <SubHeadStyle>
                                What document would you like to write?
                            </SubHeadStyle>
                        </SubHeadStyleDiv>
                        <div>
                            <PStyle>
                                Just enter your text in the prompt and start in
                                seconds...
                            </PStyle>
                        </div>
                    </TextBox>
                    <ButtonsContainer>
                        {[0, 1, 2, 3].map((buttonIndex) => (
                            <Buttons
                                key={buttonIndex}
                                style={{
                                    display:
                                        buttonIndex === currentIndex
                                            ? "flex"
                                            : "none",
                                }}
                            >
                                <ArrowImg></ArrowImg>
                                <PStyle2>
                                    {/* Text for each button */}
                                    {buttonIndex === 0 ? "Write Letters" : ""}
                                    {buttonIndex === 1 ? "Code Snippets" : ""}
                                    {buttonIndex === 2 ? "Blog Posts" : ""}
                                    {buttonIndex === 3
                                        ? "Product Description"
                                        : ""}
                                </PStyle2>
                            </Buttons>
                        ))}
                    </ButtonsContainer>
                </ColOne>
                <ColTwo lg={6} sm={12}>
                    {cardDetails.map((detail, index) => (
                        <Hero3Card
                            key={detail.title}
                            bgImg={detail.icon}
                            titleText={detail.title}
                            text={detail.description}
                        />
                    ))}
                </ColTwo>
            </Row>
        </ContainerStyle>
    );
};
export default Hero3;

export const ContainerStyle = styled(Container)`
    position: relative;
    background: #1a0ad7;
    overflow: hidden;
`;

export const ColOne = styled(Col)`
    background: #1a0ad7;
    height: 53.125rem;
    padding: 10rem 0 0 5rem;
    word-wrap: break-word;
    @media (min-width: 280px) and (max-width: 912px) {
        padding: 2.5rem 0rem 2.5rem 1.25rem;
        height: auto;
    }
    @media (min-width: 992px) and (max-width: 1400px) {
        padding: 2.5rem 0rem 2.5rem 1.25rem;
    }
`;
export const ColTwo = styled(Col)`
    background: #f6f5ff;
    height: 53.125rem;
    overflow: auto;
    direction: rtl;
    div {
        direction: ltr;
    }

    &::-webkit-scrollbar {
        position: fixed;
        width: 0.5rem;
    }
    &::-webkit-scrollbar-track {
        background: linear-gradient(white, #1a0ad7);
    }
    &::-webkit-scrollbar-thumb {
        background: #5c4ef7;
        border-radius: 6px;
    }
    @media (min-width: 280px) and (max-width: 912px) {
        height: 60vh;
    }
    @media (min-width: 992px) and (max-width: 1400px) {
    }
    @media (min-width: 2560px) {
        // height:30vh;
    }
`;

export const TextBox = styled.div`
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1rem;
    height: 23.375rem;
    width: 100%;
    text-wrap: wrap;
    padding-right: 0.5rem;
    @media (min-width: 280px) and (max-width: 912px) {
        width: 98%;
    }
    @media (min-width: 992px) and (max-width: 1400px) {
        width: 100%;
    }
`;
export const PStyle = styled.p`
    color: #dbd8fd;
    font-size: 1.3rem;
    font-weight: 700;
    line-height: 140%; /* 2.1rem */
    letter-spacing: -0.0075rem;
    @media (min-width: 280px) and (max-width: 912px) {
        font-size: 1.25rem;
        text-align: left;
    }
    @media (min-width: 992px) and (max-width: 1400px) {
        font-size: 1.25rem;
    }
`;

const SubHeadStyle = styled.h2`
    color: #fcfcfc;
    font-size: 4rem;
    font-weight: 900;
    line-height: 140%; /* 5.6rem */
    letter-spacing: -0.02rem;
    @media (min-width: 280px) and (max-width: 912px) {
        font-size: 2rem;
        text-align: left;
    }
    @media (min-width: 992px) and (max-width: 1400px) {
        font-size: 2rem;
    }
`;
const SubHeadStyleDiv = styled.div`
    width: 100%;
    @media (min-width: 280px) and (max-width: 912px) {
        width: 100%;
    }
`;
const PStyle2 = styled.p`
    color: #f6f5ff;
    text-align: center;
    font-size: 1.5rem;
    font-weight: 600;
    letter-spacing: -0.02rem;
    margin: 0;

    @media (min-width: 280px) and (max-width: 912px) {
        font-size: 1rem;
        width: 9rem;
    }
`;

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    gap: 1rem;
    overflow-y: hidden;
    @media (min-width: 280px) and (max-width: 912px) {
        flex-direction: row;
        width: auto;
        overflow-x: auto;
        margin: 1rem;
    }
`;
const ArrowImg = styled.div`
    background: url("${Arrow}") no-repeat center;
    background-size: 100% 100%;
    width: 2rem;
    height: 2.18519rem;
    position: relative;

    @media (min-width: 280px) and (max-width: 912px) {
        width: 1.625rem;
    }
`;

const animationDuration = 3000;
const pauseDuration = 0;

const scrollUp = keyframes`
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  50% {
    transform: translateY(0);
    opacity: 1;
  }
  90% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
`;

const Buttons = styled.div`
    border: 1px solid #f6f5ff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60%;
    background: linear-gradient(180deg, #5c4ef7 100%, #1a0ad7 100%);
    border-radius: 3rem;
    padding: 1rem 2rem;
    gap: 2rem;
    position: relative;
    opacity: 0;
    @media (min-width: 280px) and (max-width: 912px) {
        gap: 2rem;
        padding: 0.5rem 1rem;
        width: 20rem;
    }
    @media (min-width: 992px) and (max-width: 1400px) {
        width: 90%;
    }
    animation: ${scrollUp} ${animationDuration}ms ease-in-out forwards;
`;
