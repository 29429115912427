import Code from "../../../assets/img/Icons/SVG/Frame 52790.svg";
import Blog from "../../../assets/img/Icons/SVG/Frame 52791.svg";
import BusinessLetter from "../../../assets/img/Icons/SVG/Frame 24.svg";
import Product from "../../../assets/img/Icons/SVG/Frame 52792.svg";

export const cardDetails = [
    {
        icon: BusinessLetter,
        title: "Write Quick Accurate  Business Letter",
        description:
            "With docuhelp.ai users can write detailed formal letters with 100% accuracy",
    },
    {
        icon: Code,
        title: " Generate Code Snippets for Your Projects",
        description:
            "With docuhelp.ai users can write detailed formal letters with 100% accuracy",
    },
    {
        icon: Blog,
        title: "Write Captivating Blog Posts With Docuhelp.ai",
        description:
            "With docuhelp.ai users can write detailed formal letters with 100% accuracy",
    },
    {
        icon: Product,
        title: "Generate Accurate Product Description",
        description:
            "With docuhelp.ai users can write detailed formal letters with 100% accuracy",
    },
];
