import { BrowserRouter, Routes, Route } from "react-router-dom";
import Landing from "./views/landing/index.js";
import PrivacyPolicy from "./components/privacyPolicy/PrivacyPolicy.js";

function App() {
    return (
        // <BrowserRouter basename="/">
        //     <Routes>
        //         <Route path="/" element={<Landing />} />
        //         <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        //     </Routes>
        // </BrowserRouter>
        <Landing/>
        // <PrivacyPolicy />
    );
}

export default App;
